import { useEffect, useState } from "react";
export function useSerializedPCD(proofPackage, serializedPCD) {
    const [pcd, setPCD] = useState();
    useEffect(() => {
        if (serializedPCD) {
            const parsedPCD = JSON.parse(decodeURIComponent(serializedPCD));
            if (parsedPCD.type !== proofPackage.name) {
                return;
            }
            proofPackage.deserialize(parsedPCD.pcd).then((pcd) => {
                setPCD(pcd);
            });
        }
    }, [proofPackage, serializedPCD, setPCD]);
    return pcd;
}
