import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Provider } from "@rollbar/react";
export { ErrorBoundary } from "@rollbar/react";
export function RollbarProvider({ children, config }) {
    if (config.accessToken === undefined ||
        config.environmentName === undefined) {
        console.log("[ROLLBAR] missing environment variable - not starting");
        return _jsx(_Fragment, { children: children });
    }
    const rollbarConfig = {
        accessToken: config.accessToken,
        environment: config.environmentName,
        captureUncaught: true,
        captureUnhandledRejections: true,
        payload: {
            client: {
                javascript: {
                    source_map_enabled: true
                }
            }
        }
    };
    console.log("[ROLLBAR] started");
    return _jsx(Provider, { config: rollbarConfig, children: children });
}
