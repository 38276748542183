import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Asks the server to prove a PCD asynchronously. Returns a
 * {@link PendingPCD}.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestProveOnServer(zupassServerUrl, serverReq) {
    return httpPostSimple(urlJoin(zupassServerUrl, `/pcds/prove`), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), serverReq);
}
