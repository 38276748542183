import urljoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Asks the server to delete the user's account, and all data associated with
 * it.
 */
export async function requestDeleteAccount(zupassServerUrl, req) {
    return httpPostSimple(urljoin(zupassServerUrl, "/account/delete"), async () => ({
        value: undefined,
        success: true
    }), req);
}
