import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Asks the server to perform an action in relation to a ticket issued by
 * Podbox, such as check-in or badge gifting.
 * {@link credential} is a Semaphore Signature of a payload
 * that is a `JSON.stringify`-ed {@link CredentialPayload}.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestPodboxTicketAction(checkInUrl, credential, action, ticketId, eventId) {
    return httpPostSimple(urlJoin(checkInUrl), async (resText) => {
        return {
            success: true,
            value: JSON.parse(resText)
        };
    }, {
        credential,
        action,
        ticketId,
        eventId
    });
}
