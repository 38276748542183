/**
 * Returns the first error from a list of {@link APIResult}s.
 */
export function getError(...results) {
    for (const result of results) {
        if (result && !result.success) {
            return result.error;
        }
    }
    return undefined;
}
