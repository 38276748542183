import urljoin from "url-join";
import { httpGetSimple } from "./makeRequest.js";
/**
 * Hits an endpoint to download anonymized email to pretix order code mappings.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestPodboxOneClickEmails(podboxServerUrl, pipelineId, apiKey) {
    return httpGetSimple(urljoin(podboxServerUrl, "/generic-issuance/api/one-click-emails/", pipelineId, apiKey), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }));
}
