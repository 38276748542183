export class Emitter {
    constructor() {
        this.listeners = new Set();
    }
    listen(l) {
        this.listeners.add(l);
        return () => this.listeners.delete(l);
    }
    emit(t) {
        for (const listener of Array.from(this.listeners)) {
            listener(t);
        }
    }
}
export function wrap(t) {
    return { value: t };
}
