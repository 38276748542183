/**
 * Regex matching legal names for POD entries.  This is intended to make
 * entry names usable as identifiers in TypeScript and other languages.
 */
export const POD_NAME_REGEX = new RegExp(/^[A-Za-z_]\w*$/);
/**
 * Identifier for EdDSA public key string type.
 */
export const EDDSA_PUBKEY_TYPE_STRING = "eddsa_pubkey";
/**
 * Regex matching legal values for types encoded as strings by
 * {@link podValueToRawValue}. This matches strings of the form
 * `pod_${PODName}:${string}`.
 */
export const POD_STRING_TYPE_REGEX = new RegExp(/pod_([A-Za-z_]\w*):(.*)$/);
/**
 * Minimum legal value of a `cryptographic` entry value.
 */
export const POD_CRYPTOGRAPHIC_MIN = 0n;
/**
 * Maximum legal value of a `cryptographic` entry value.
 */
export const POD_CRYPTOGRAPHIC_MAX = 
// Prime order of the alt_bn128 curve.
// Should always be equal to the `r` constant in @zk-kit/baby-jubjub.
21888242871839275222246405745257275088548364400416034343698204186575808495617n -
    1n;
/**
 * Minimum legal value of an `int` entry value.
 */
export const POD_INT_MIN = -(1n << 63n);
/**
 * Maximum legal value of an `int` entry value.
 */
export const POD_INT_MAX = (1n << 63n) - 1n;
/**
 * Type constructor for EdDSA (Baby Jubjub) public keys.
 */
export function PODEdDSAPublicKeyValue(value) {
    return { type: EDDSA_PUBKEY_TYPE_STRING, value };
}
/**
 * Minimum legal value of a `date` entry value.
 */
export const POD_DATE_MIN = new Date(-8640000000000000);
/**
 * Maximum legal value of a `date` entry value.
 */
export const POD_DATE_MAX = new Date(8640000000000000);
/**
 * Convenience constant for a null PODValue.  Note that since users are not
 * required to use this constant, PODNullValues should not be compared using
 * `===`, but by examining the type or value fields.
 */
export const PODNull = { type: "null", value: null };
/**
 * Fixed hash value representing a PODNullValue in a circuit.
 */
export const POD_NULL_HASH = 0x1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1d1dn;
