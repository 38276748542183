/**
 * The globally unique type name of the {@link EdDSAFrogPCD}.
 */
export const EdDSAFrogPCDTypeName = "eddsa-frog-pcd";
/**
 * Assigns each currently supported Biome a unique value.
 */
export var Biome;
(function (Biome) {
    Biome[Biome["Unknown"] = 0] = "Unknown";
    Biome[Biome["Jungle"] = 1] = "Jungle";
    Biome[Biome["Desert"] = 2] = "Desert";
    Biome[Biome["Swamp"] = 3] = "Swamp";
    Biome[Biome["TheCapital"] = 4] = "TheCapital";
    Biome[Biome["PutridSwamp"] = 5] = "PutridSwamp";
    Biome[Biome["CelestialPond"] = 6] = "CelestialPond";
    Biome[Biome["TheWrithingVoid"] = 7] = "TheWrithingVoid";
})(Biome || (Biome = {}));
/**
 * Assigns each currently supported Rarity a unique value.
 */
export var Rarity;
(function (Rarity) {
    Rarity[Rarity["Unknown"] = 0] = "Unknown";
    Rarity[Rarity["Common"] = 1] = "Common";
    Rarity[Rarity["Rare"] = 2] = "Rare";
    Rarity[Rarity["Epic"] = 3] = "Epic";
    Rarity[Rarity["Legendary"] = 4] = "Legendary";
    Rarity[Rarity["Mythic"] = 5] = "Mythic";
    Rarity[Rarity["Object"] = 6] = "Object";
})(Rarity || (Rarity = {}));
/**
 * Assigns each currently supported Temperament a unique value.
 */
export var Temperament;
(function (Temperament) {
    Temperament[Temperament["UNKNOWN"] = 0] = "UNKNOWN";
    Temperament[Temperament["N_A"] = 1] = "N_A";
    Temperament[Temperament["ANGY"] = 2] = "ANGY";
    Temperament[Temperament["BORD"] = 3] = "BORD";
    Temperament[Temperament["CALM"] = 4] = "CALM";
    Temperament[Temperament["CHUB"] = 5] = "CHUB";
    Temperament[Temperament["COOL"] = 6] = "COOL";
    Temperament[Temperament["DARK"] = 7] = "DARK";
    Temperament[Temperament["DOOM"] = 8] = "DOOM";
    Temperament[Temperament["HMBL"] = 9] = "HMBL";
    Temperament[Temperament["HNGY"] = 10] = "HNGY";
    Temperament[Temperament["HRNY"] = 11] = "HRNY";
    Temperament[Temperament["HYPE"] = 12] = "HYPE";
    Temperament[Temperament["MEOW"] = 13] = "MEOW";
    Temperament[Temperament["OKAY"] = 14] = "OKAY";
    Temperament[Temperament["PUFF"] = 15] = "PUFF";
    Temperament[Temperament["SADG"] = 16] = "SADG";
    Temperament[Temperament["SLLY"] = 17] = "SLLY";
    Temperament[Temperament["SLPY"] = 18] = "SLPY";
    Temperament[Temperament["WISE"] = 19] = "WISE";
    Temperament[Temperament["WOW"] = 20] = "WOW";
    Temperament[Temperament["YOLO"] = 21] = "YOLO";
})(Temperament || (Temperament = {}));
export const COMMON_TEMPERAMENT_SET = [
    Temperament.HNGY,
    Temperament.ANGY,
    Temperament.SADG,
    Temperament.CALM,
    Temperament.BORD,
    Temperament.DARK,
    Temperament.SLPY,
    Temperament.CALM
];
/**
 * The EdDSA Frog PCD enables the verification that a specific  {@link EdDSAFrogPCDClaim}
 * has been signed with an EdDSA private key. The {@link EdDSAFrogPCDProof} contains a EdDSA
 * PCD and serves as the signature.
 */
export class EdDSAFrogPCD {
    constructor(id, claim, proof) {
        this.type = EdDSAFrogPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
/**
 * Returns true if a PCD is an EdDSA Ticket PCD, or false otherwise.
 */
export function isEdDSAFrogPCD(pcd) {
    return pcd.type === EdDSAFrogPCDTypeName;
}
