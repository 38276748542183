import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Asks the server to clear the cache for a given pipeline.
 */
export async function requestGenericIssuanceClearPipelineCache(zupassServerUrl, req) {
    return httpPostSimple(urlJoin(zupassServerUrl, `/generic-issuance/api/clear-pipeline-cache`), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), req, true);
}
