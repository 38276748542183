import urlJoin from "url-join";
import { httpPost } from "./makeRequest.js";
/**
 * Sends a request to the server to remove an email address from being
 * associated with their Zupass account.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestRemoveUserEmail(zupassServerUrl, emailToRemove, pcd) {
    return httpPost(urlJoin(zupassServerUrl, "/account/delete-email"), {
        onValue: async (resText) => ({
            value: JSON.parse(resText),
            success: true
        }),
        onError: async (resText, code) => ({
            error: resText,
            success: false,
            code
        })
    }, {
        emailToRemove,
        pcd
    });
}
