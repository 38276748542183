// Custom schema rendering logic -- long-term, we should switch this out with a schema library
export function getTitleEntry(pcd) {
    if (pcd.claim.entries["pod_type"]?.value === "frogcrypto.frog") {
        return pcd.claim.entries["name"];
    }
    return pcd.claim.entries["zupass_title"];
}
export function getDescriptionEntry(pcd) {
    if (pcd.claim.entries["pod_type"]?.value === "frogcrypto.frog") {
        return pcd.claim.entries["description"];
    }
    return pcd.claim.entries["zupass_description"];
}
export function getImageUrlEntry(pcd) {
    if (pcd.claim.entries["pod_type"]?.value === "frogcrypto.frog") {
        return pcd.claim.entries["imageUrl"];
    }
    return pcd.claim.entries["zupass_image_url"];
}
