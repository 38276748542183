export function isFulfilled(result) {
    return result.status === "fulfilled";
}
export async function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms ?? 1);
    });
}
