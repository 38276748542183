import { Emitter } from "@pcd/emitter";
import { getHash } from "@pcd/passport-crypto";
import stringify from "fast-json-stable-stringify";
import _ from "lodash";
import { isAppendToFolderAction, isDeleteFolderAction, isReplaceInFolderAction } from "./actions.js";
import { isAppendToFolderPermission, isDeleteFolderPermission, isReplaceInFolderPermission } from "./permissions.js";
import { getFoldersInFolder, isFolderAncestor, isRootFolder } from "./util.js";
export function matchActionToPermission(action, permissions) {
    for (const permission of permissions) {
        if (isAppendToFolderAction(action) &&
            isAppendToFolderPermission(permission) &&
            (action.folder === permission.folder ||
                isFolderAncestor(action.folder, permission.folder))) {
            return { action, permission };
        }
        if (isReplaceInFolderAction(action) &&
            isReplaceInFolderPermission(permission) &&
            (action.folder === permission.folder ||
                isFolderAncestor(action.folder, permission.folder))) {
            return { action, permission };
        }
        if (isDeleteFolderAction(action) &&
            isDeleteFolderPermission(permission) &&
            (action.folder === permission.folder ||
                isFolderAncestor(action.folder, permission.folder))) {
            return { action, permission };
        }
    }
    return null;
}
/**
 * This class represents all the PCDs a user may have, and also
 * contains references to all the relevant {@link PCDPackage}s,
 * which allows this class to effectively make use of all of the
 * PCDs.
 */
export class PCDCollection {
    constructor(packages, pcds, folders) {
        this.packages = packages;
        this.pcds = pcds ?? [];
        this.folders = folders ?? {};
        this.changeEmitter = new Emitter();
    }
    getFoldersInFolder(folderPath) {
        return getFoldersInFolder(folderPath, Object.values(this.folders));
    }
    isValidFolder(folderPath) {
        return Object.values(this.folders).includes(folderPath);
    }
    setPCDFolder(pcdId, folder) {
        if (!this.hasPCDWithId(pcdId)) {
            throw new Error(`can't set folder of pcd ${pcdId} - pcd doesn't exist`);
        }
        this.folders[pcdId] = folder;
        this.emitChange();
    }
    async tryExec(action, permissions) {
        const match = matchActionToPermission(action, permissions);
        if (!match) {
            return false;
        }
        try {
            const result = await this.tryExecutingActionWithPermission(match.action, match.permission);
            if (result) {
                return true;
            }
        }
        catch (e) {
            // An exception here should be rare: trying to add the same PCD twice
            // or to multiple folders. Regular permission failures are not
            // exceptions.
            console.log(e);
            return false;
        }
        return false;
    }
    async tryExecutingActionWithPermission(action, permission) {
        if (isAppendToFolderAction(action) &&
            isAppendToFolderPermission(permission)) {
            if (action.folder !== permission.folder &&
                !isFolderAncestor(action.folder, permission.folder)) {
                return false;
            }
            const pcds = await this.deserializeAll(action.pcds);
            for (const pcd of pcds) {
                if (this.hasPCDWithId(pcd.id)) {
                    throw new Error(`pcd with ${pcd.id} already exists`);
                }
            }
            this.addAll(pcds);
            this.bulkSetFolder(pcds.map((pcd) => pcd.id), action.folder);
            return true;
        }
        if (isReplaceInFolderAction(action) &&
            isReplaceInFolderPermission(permission)) {
            if (action.folder !== permission.folder &&
                !isFolderAncestor(action.folder, permission.folder)) {
                return false;
            }
            const pcds = await this.deserializeAll(action.pcds);
            for (const pcd of pcds) {
                if (this.hasPCDWithId(pcd.id) &&
                    this.getFolderOfPCD(pcd.id) !== action.folder) {
                    throw new Error(`pcd with ${pcd.id} already exists outside the allowed folder`);
                }
            }
            this.addAll(pcds, { upsert: true });
            this.bulkSetFolder(pcds.map((pcd) => pcd.id), action.folder);
            return true;
        }
        if (isDeleteFolderAction(action) && isDeleteFolderPermission(permission)) {
            if (action.folder !== permission.folder &&
                !isFolderAncestor(action.folder, permission.folder)) {
                return false;
            }
            this.deleteFolder(action.folder, action.recursive);
            return true;
        }
        return false;
    }
    getSize() {
        return this.pcds.length;
    }
    getAllFolderNames() {
        const result = new Set();
        Object.entries(this.folders).forEach(([_pcdId, folder]) => result.add(folder));
        return Array.from(result);
    }
    bulkSetFolder(pcdIds, folder) {
        pcdIds.forEach((pcdId) => {
            if (!this.hasPCDWithId(pcdId)) {
                throw new Error(`can't set folder of pcd ${pcdId} - pcd doesn't exist`);
            }
        });
        pcdIds.forEach((pcdId) => {
            this.folders[pcdId] = folder;
        });
        this.emitChange();
    }
    setFolder(pcdId, folder) {
        this.bulkSetFolder([pcdId], folder);
    }
    getFolderOfPCD(pcdId) {
        if (!this.hasPCDWithId(pcdId)) {
            return undefined;
        }
        return Object.entries(this.folders).find(([id, _folder]) => pcdId === id)?.[1];
    }
    getAllPCDsInFolder(folder) {
        if (isRootFolder(folder)) {
            const pcdIdsInFolders = new Set([...Object.keys(this.folders)]);
            const pcdsNotInFolders = this.pcds.filter((pcd) => !pcdIdsInFolders.has(pcd.id));
            return pcdsNotInFolders;
        }
        const pcdIds = Object.entries(this.folders)
            .filter(([_pcdId, f]) => f === folder)
            .map(([pcdId, _f]) => pcdId);
        return this.getByIds(pcdIds);
    }
    removeAllPCDsInFolder(folder) {
        const inFolder = this.getAllPCDsInFolder(folder);
        inFolder.forEach((pcd) => this.remove(pcd.id));
    }
    replacePCDsInFolder(folder, pcds) {
        this.removeAllPCDsInFolder(folder);
        this.addAll(pcds, { upsert: true });
        pcds.forEach((pcd) => this.setPCDFolder(pcd.id, folder));
    }
    /**
     * Removes all PCDs within a given folder, and optionally within all
     * subfolders.
     */
    deleteFolder(folder, recursive) {
        const folders = [folder];
        if (recursive) {
            const subFolders = Object.values(this.folders).filter((folderPath) => {
                return isFolderAncestor(folderPath, folder);
            });
            folders.push(..._.uniq(subFolders));
        }
        for (const folderPath of folders) {
            this.removeAllPCDsInFolder(folderPath);
        }
    }
    getPackage(name) {
        const matching = this.packages.find((p) => p.name === name);
        return matching;
    }
    hasPackage(name) {
        return this.packages.find((p) => p.name === name) !== undefined;
    }
    async serialize(pcd) {
        const pcdPackage = this.getPackage(pcd.type);
        if (!pcdPackage)
            throw new Error(`no package matching ${pcd.type}`);
        const serialized = await pcdPackage.serialize(pcd);
        return serialized;
    }
    async serializeAll() {
        return Promise.all(this.pcds.map(this.serialize.bind(this)));
    }
    async serializeCollection() {
        return stringify({
            pcds: await Promise.all(this.pcds.map(this.serialize.bind(this))),
            folders: this.folders
        });
    }
    async deserialize(serialized, options) {
        const pcdPackage = this.getPackage(serialized.type);
        try {
            if (!pcdPackage)
                throw new Error(`no package matching ${serialized.type}`);
            const deserialized = await pcdPackage.deserialize(serialized.pcd);
            return deserialized;
        }
        catch (firstError) {
            if (options?.fallbackDeserializeFunction) {
                try {
                    return await options.fallbackDeserializeFunction(this, pcdPackage, serialized, firstError);
                }
                catch (fallbackError) {
                    // Fallback also failed, so fallthrough to re-throw the original error
                }
            }
            throw firstError;
        }
    }
    async deserializeAll(serialized, options) {
        return Promise.all(serialized.map(async (serialized) => this.deserialize(serialized, options)));
    }
    async deserializeAllAndAdd(serialized, options) {
        const deserialized = await this.deserializeAll(serialized, options);
        this.addAll(deserialized, options);
    }
    async remove(pcdId) {
        this.pcds = this.pcds.filter((pcd) => pcd.id !== pcdId);
        this.folders = Object.fromEntries(Object.entries(this.folders).filter(([id]) => id !== pcdId));
        this.emitChange();
    }
    async deserializeAndAdd(serialized, options) {
        await this.deserializeAllAndAdd([serialized], options);
    }
    add(pcd, options) {
        this.addAll([pcd], options);
    }
    addAll(pcds, options) {
        const currentMap = new Map(this.pcds.map((pcd) => [pcd.id, pcd]));
        const toAddMap = new Map(pcds.map((pcd) => [pcd.id, pcd]));
        for (const [id, pcd] of Array.from(toAddMap.entries())) {
            if (currentMap.has(id) && !options?.upsert) {
                throw new Error(`pcd with id ${id} is already in this collection`);
            }
            currentMap.set(id, pcd);
        }
        this.pcds = Array.from(currentMap.values());
        this.emitChange();
    }
    size() {
        return this.pcds.length;
    }
    getAll() {
        return this.pcds;
    }
    getAllIds() {
        return this.getAll().map((pcd) => pcd.id);
    }
    getByIds(ids) {
        return this.pcds.filter((pcd) => ids.find((id) => pcd.id === id) !== undefined);
    }
    /**
     * Generates a unique hash based on the contents. This hash changes whenever
     * the set of pcds, or the contents of the pcds changes.
     */
    async getHash() {
        const allSerialized = await this.serializeCollection();
        const hashed = await getHash(allSerialized);
        return hashed;
    }
    getById(id) {
        return this.pcds.find((pcd) => pcd.id === id);
    }
    hasPCDWithId(id) {
        return this.getById(id) !== undefined;
    }
    getPCDsByType(type) {
        return this.pcds.filter((pcd) => pcd.type === type);
    }
    emitChange() {
        // Emit the change asynchronously, so we don't need to delay until
        // listeners are complete.
        setTimeout(() => this.changeEmitter.emit(), 0);
    }
    static async deserialize(packages, serialized, options) {
        const parsed = JSON.parse(serialized);
        const collection = new PCDCollection(packages, []);
        const serializedPcdsList = parsed.pcds ?? [];
        const parsedFolders = parsed.folders ?? {};
        const pcds = await Promise.all(serializedPcdsList.map(async (serialized) => collection.deserialize(serialized, options)));
        collection.addAll(pcds, { upsert: true });
        collection.folders = parsedFolders;
        return collection;
    }
    /**
     * Merges another PCD collection into this one.
     * There is one option:
     * - `shouldInclude` is a function used to filter out PCDs from the other
     *   collection during merging, e.g. to filter out duplicates or PCDs of
     *   a type that should not be copied.
     */
    merge(other, options) {
        let pcds = other.getAll();
        // If the caller has specified a filter function, run that first to filter
        // out unwanted PCDs from the merge.
        if (options?.shouldInclude) {
            pcds = pcds.filter((pcd) => options.shouldInclude?.(pcd, this));
        }
        this.addAll(pcds, { upsert: true });
        for (const pcd of pcds) {
            if (other.folders[pcd.id]) {
                this.setFolder(pcd.id, other.folders[pcd.id]);
            }
        }
    }
}
