import urlJoin from "url-join";
import { onNamedAPIError } from "./apiResult.js";
import { httpPost } from "./makeRequest.js";
/**
 * Updates the blob key that encrypts a user's storage and updates the salt used to
 * generate the preimage to that blob key. If this request succeeds, the user's storage
 * is no longer accessible with the old blob key and the user's salt is guaranteed to
 * be different.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestChangeBlobKey(zupassServerUrl, oldBlobKey, newBlobKey, uuid, newSalt, encryptedStorage, knownRevision, credential) {
    return httpPost(urlJoin(zupassServerUrl, `/sync/v3/changeBlobKey`), {
        onValue: async (resText) => ({
            value: JSON.parse(resText),
            success: true
        }),
        onError: onNamedAPIError
    }, {
        oldBlobKey,
        newBlobKey,
        newSalt,
        encryptedBlob: JSON.stringify(encryptedStorage),
        uuid,
        knownRevision,
        pcd: credential
    });
}
