import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Asks the server to verify the given email login token.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestVerifyToken(zupassServerUrl, email, token) {
    return httpPostSimple(urlJoin(zupassServerUrl, `/account/verify-token`), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), { email, token });
}
