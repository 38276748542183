import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Update the Telegram handle sharing status of a user.
 */
export async function requestFrogCryptoUpdateTelegramHandleSharing(zupassServerUrl, req) {
    return httpPostSimple(urlJoin(zupassServerUrl, "/frogcrypto/telegram-handle-sharing"), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), req);
}
