import { getSodium } from "./libsodium.js";
import * as utils from "./utils.js";
import { arrayBufferToHexString } from "./utils.js";
/**
 * This class contains cryptographic primitives that are used by the PCD
 * application SDK and downstream packages.
 */
export class PCDCrypto {
    static async newInstance(sodium) {
        sodium = sodium ?? (await getSodium());
        return new PCDCrypto(sodium);
    }
    constructor(sodium) {
        this.sodium = sodium;
    }
    cryptoHash(str) {
        return arrayBufferToHexString(this.sodium.crypto_hash(str));
    }
    generateRandomKey(bits = 256) {
        const bytes = bits / 8;
        const arrayBuffer = utils
            .getCrypto()
            .getRandomValues(new Uint8Array(bytes));
        return utils.arrayBufferToHexString(arrayBuffer);
    }
    /**
     * Combines generateSalt and argon2 function, returns both a random salt
     * and the resulting 32-byte encryption key.
     */
    generateSaltAndEncryptionKey(password) {
        const salt = this.generateSalt();
        const key = this.argon2(password, salt, 32);
        return { key, salt };
    }
    generateSalt(length = this.sodium.crypto_pwhash_SALTBYTES) {
        if (length < this.sodium.crypto_pwhash_SALTBYTES) {
            throw Error(`Salt must be at least ${this.sodium.crypto_pwhash_SALTBYTES}`);
        }
        const buffer = this.sodium.randombytes_buf(length);
        return utils.arrayBufferToHexString(buffer);
    }
    argon2(password, salt, length = 32) {
        const result = this.sodium.crypto_pwhash(length, utils.stringToArrayBuffer(password), utils.hexStringToArrayBuffer(salt), 
        // `oplimit` represents the maximum amount of computations to perform
        // for generating a key. `crypto_pwhash_OPSLIMIT_INTERACTIVE` is recommended
        // for intereactive, online applications such as Zupass.
        // Source: https://libsodium.gitbook.io/doc/password_hashing/default_phf
        this.sodium.crypto_pwhash_OPSLIMIT_INTERACTIVE, 
        // `memlimit` represents the maximum amount of RAM in bytes thet function
        // will use. It is required to use the same values for `opslimit` and `memlimit`.
        this.sodium.crypto_pwhash_MEMLIMIT_INTERACTIVE, this.sodium.crypto_pwhash_ALG_DEFAULT, "hex");
        return result;
    }
    xchacha20Encrypt(plaintext, nonce, key, assocData) {
        if (nonce.length !== 48) {
            throw Error("Nonce must be 24 bytes");
        }
        const arrayBuffer = this.sodium.crypto_aead_xchacha20poly1305_ietf_encrypt(plaintext, assocData || null, null, utils.hexStringToArrayBuffer(nonce), utils.hexStringToArrayBuffer(key));
        return utils.arrayBufferToBase64(arrayBuffer);
    }
    xchacha20Decrypt(ciphertext, nonce, key, assocData) {
        if (nonce.length !== 48) {
            throw Error("Nonce must be 24 bytes");
        }
        try {
            return this.sodium.crypto_aead_xchacha20poly1305_ietf_decrypt(null, utils.base64ToArrayBuffer(ciphertext), assocData || null, utils.hexStringToArrayBuffer(nonce), utils.hexStringToArrayBuffer(key), "text");
        }
        catch {
            return null;
        }
    }
    randombytesDeterministic(length, seed) {
        try {
            return this.sodium.randombytes_buf_deterministic(length, seed);
        }
        catch {
            return null;
        }
    }
}
