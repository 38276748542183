import urlJoin from "url-join";
import { httpPost } from "./makeRequest.js";
/**
 * Sends a request to the server to add a new email address to a user's account.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestAddUserEmail(zupassServerUrl, newEmail, pcd, 
/**
 * If absent, requests a confirmation code; if present redeems it and performs the update.
 */
confirmationCode) {
    return httpPost(urlJoin(zupassServerUrl, "/account/add-email"), {
        onValue: async (resText) => ({
            value: JSON.parse(resText),
            success: true
        }),
        onError: async (resText, code) => ({
            error: resText,
            success: false,
            code
        })
    }, {
        newEmail,
        pcd,
        confirmationCode
    });
}
