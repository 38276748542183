export const PCDActionType = {
    ReplaceInFolder: "ReplaceInFolder_action",
    AppendToFolder: "AppendToFolder_action",
    DeleteFolder: "DeleteFolder_action"
};
export function getPcdsFromActions(actions) {
    let results = [];
    actions.forEach((action) => {
        if (isAppendToFolderAction(action) || isReplaceInFolderAction(action)) {
            results = [...results, ...action.pcds];
        }
    });
    return results;
}
export function isReplaceInFolderAction(action) {
    return action.type === PCDActionType.ReplaceInFolder;
}
export function expectIsReplaceInFolderAction(action) {
    if (action.type !== PCDActionType.ReplaceInFolder) {
        throw new Error("Expected action to be a ReplaceInFolderAction");
    }
}
export function isAppendToFolderAction(action) {
    return action.type === PCDActionType.AppendToFolder;
}
export function isDeleteFolderAction(action) {
    return action.type === PCDActionType.DeleteFolder;
}
