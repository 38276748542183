import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Get user specific FrogCrypto state.
 *
 * @see {@link FrogCryptoUserStateRequest}
 */
export async function requestFrogCryptoGetUserState(zupassServerUrl, req) {
    return httpPostSimple(urlJoin(zupassServerUrl, "/frogcrypto/user-state"), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), req);
}
