// This enum should never overlap with PCDActionType
// See test "hould not allow action types to be assigned to permission
// types or vice-versa" in permissions.spec.ts
export const PCDPermissionType = {
    ReplaceInFolder: "ReplaceInFolder_permission",
    AppendToFolder: "AppendToFolder_permission",
    DeleteFolder: "DeleteFolder_permission"
};
export function isPCDFolderPermission(permission) {
    return [
        PCDPermissionType.AppendToFolder,
        PCDPermissionType.ReplaceInFolder,
        PCDPermissionType.DeleteFolder
    ].includes(permission.type);
}
export function isAppendToFolderPermission(permission) {
    return permission.type === PCDPermissionType.AppendToFolder;
}
export function isReplaceInFolderPermission(permission) {
    return permission.type === PCDPermissionType.ReplaceInFolder;
}
export function isDeleteFolderPermission(permission) {
    return permission.type === PCDPermissionType.DeleteFolder;
}
