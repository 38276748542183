export const EmailPCDTypeName = "email-pcd";
export class EmailPCD {
    constructor(id, claim, proof) {
        this.type = EmailPCDTypeName;
        this.id = id;
        this.claim = claim;
        this.proof = proof;
    }
}
export const isEmailPCD = (pcd) => pcd.type === EmailPCDTypeName;
