import { httpGetSimple } from "./makeRequest.js";
/**
 * Hits an endpoint to download a semaphore protocol group.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestSemaphoreGroup(semaphoreGroupUrl) {
    return httpGetSimple(semaphoreGroupUrl, async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }));
}
