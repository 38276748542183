import { sha256 } from "js-sha256";
export function hashProveRequest(req) {
    const reqString = JSON.stringify(req);
    return sha256(reqString);
}
export var PendingPCDStatus;
(function (PendingPCDStatus) {
    PendingPCDStatus["QUEUED"] = "queued";
    PendingPCDStatus["PROVING"] = "proving";
    PendingPCDStatus["COMPLETE"] = "complete";
    PendingPCDStatus["ERROR"] = "error";
    PendingPCDStatus["NONE"] = "none";
})(PendingPCDStatus || (PendingPCDStatus = {}));
export function isSettledPendingPCDStatus(status) {
    return [
        PendingPCDStatus.ERROR,
        PendingPCDStatus.COMPLETE,
        PendingPCDStatus.NONE
    ].includes(status);
}
