import urlJoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Asks the server to fetch the Pretix products for the given organizer URL and API token.
 */
export async function requestGenericIssuanceFetchPretixProducts(zupassServerUrl, req) {
    return httpPostSimple(urlJoin(zupassServerUrl, `/generic-issuance/api/fetch-pretix-products`), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), req, true);
}
