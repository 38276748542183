import urljoin from "url-join";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Sets a ticket's check-in state to the provided boolean value.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestGenericIssuanceSetManualCheckInState(zupassServerUrl, pipelineId, key, ticketId, checkInState) {
    return httpPostSimple(urljoin(zupassServerUrl, "/generic-issuance/api/manual-checkin", pipelineId, key), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), {
        ticketId,
        checkInState
    });
}
