import urljoin from "url-join";
import { httpGetSimple } from "./makeRequest.js";
/**
 * Hits an endpoint to download a semaphore protocol group.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestGenericIssuanceHistoricalSemaphoreGroup(zupassServerUrl, pipelineId, groupId, rootHash) {
    return httpGetSimple(urljoin(zupassServerUrl, "/generic-issuance/api/semaphore", pipelineId, groupId, rootHash), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }));
}
