/**
 * The globally unique type name of the {@link PODPCD}.
 */
export const PODPCDTypeName = "pod-pcd";
/**
 * The POD PCD enables the verification that a specific set of POD entries
 * has been signed with an EdDSA private key. The {@link PODPCDClaim} contains
 * the entries and public key, while the {@link PODPCDProof} contains the
 * signature.
 *
 * All operations are coordinated via a `POD` object available as `pcd.pod`.
 * This object generates derived data (such as the Merkle tree and content ID)
 * lazily as needed.
 *
 * Note that a POD PCD is not intended to be mutable.  The `POD` object is not
 * updated after the PCD is proven or deserialized.
 */
export class PODPCD {
    /**
     * Gets a POD object for manipulating this PCD's content.
     */
    get pod() {
        return this._pod;
    }
    /**
     * Create a PCD to encapsulate the given ID and POD object.
     */
    constructor(id, pod) {
        this.type = PODPCDTypeName;
        this.id = id;
        this.claim = {
            entries: pod.content.asEntries(),
            signerPublicKey: pod.signerPublicKey
        };
        this.proof = { signature: pod.signature };
        this._pod = pod;
    }
}
/**
 * Convenience function for checking the type of a PODPCD.
 */
export function isPODPCD(pcd) {
    return pcd.type === PODPCDTypeName;
}
