import { ArgumentTypeName } from "@pcd/pcd-types";
import { SemaphoreIdentityPCDPackage } from "@pcd/semaphore-identity-pcd";
import { SemaphoreSignaturePCDPackage } from "@pcd/semaphore-signature-pcd";
import { httpPostSimple } from "./makeRequest.js";
/**
 * Agrees to a given version of the legal terms.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestAgreeTerms(passportServerUrl, req) {
    return httpPostSimple(`${passportServerUrl}/account/agree-terms`, async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), req);
}
export async function agreeTerms(zupassServerUrl, version, identityV3) {
    return requestAgreeTerms(zupassServerUrl, {
        // A generic authenticated route solution might make this much simpler
        pcd: await SemaphoreSignaturePCDPackage.serialize(await SemaphoreSignaturePCDPackage.prove({
            identity: {
                argumentType: ArgumentTypeName.PCD,
                value: await SemaphoreIdentityPCDPackage.serialize(await SemaphoreIdentityPCDPackage.prove({
                    identityV3
                }))
            },
            signedMessage: {
                argumentType: ArgumentTypeName.String,
                value: JSON.stringify({ version })
            }
        }))
    });
}
