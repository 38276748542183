export class FeedHost {
    constructor(feeds, providerUrl, providerName) {
        this.hostedFeed = feeds;
        this.providerUrl = providerUrl;
        this.providerName = providerName;
    }
    getProviderUrl() {
        return this.providerUrl;
    }
    getProviderName() {
        return this.providerName;
    }
    async handleFeedRequest(request) {
        const feed = this.hostedFeed.find((f) => f.feed.id === request.feedId);
        if (!feed) {
            throw new Error(`couldn't find feed with id ${request.feedId}`);
        }
        const response = await feed.handleRequest(request);
        return response;
    }
    async handleListFeedsRequest(_request) {
        return {
            providerName: this.providerName,
            providerUrl: this.providerUrl,
            feeds: this.hostedFeed.map((f) => f.feed)
        };
    }
    hasFeedWithId(feedId) {
        return this.hostedFeed.filter((f) => f.feed.id === feedId).length > 0;
    }
    async handleListSingleFeedRequest(_request) {
        return {
            providerUrl: this.providerUrl,
            providerName: this.providerName,
            feeds: this.hostedFeed
                .filter((f) => f.feed.id === _request.feedId)
                .map((f) => f.feed)
        };
    }
}
