import { PODPipelineInputFieldType } from "@pcd/passport-interface";
/**
 * The TemplatedColumn class represents a column whose data is of a given type.
 * The `is` method makes it possible for TypeScript to determine the type of
 * data the column holds, and for the `getValue()` method to be strongly typed.
 *
 * For example, if the column has type PODPipelineInputFieldType.String, then
 * the `getValue()` method will return a JavaScript string.
 */
export class TemplatedColumn {
    constructor(name, type) {
        this.name = name;
        this.type = type;
    }
    getName() {
        return this.name;
    }
    getValue(row) {
        return row[this.name];
    }
    is(type) {
        return this.type === type;
    }
}
