import urljoin from "url-join";
import { httpGetSimple } from "./makeRequest.js";
/**
 * Returns the latest root hash for a group.
 *
 * Never rejects. All information encoded in the resolved response.
 */
export async function requestGenericIssuanceSemaphoreGroupRoot(zupassServerUrl, pipelineId, groupId) {
    return httpGetSimple(urljoin(zupassServerUrl, "/generic-issuance/api/semaphore", pipelineId, groupId, "latest-root"), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }));
}
