import urlJoin from "url-join";
import { httpGetSimple } from "./makeRequest.js";
/**
 * Asks the server to fetch the ticket previews for the given email and order code.
 */
export async function requestGenericIssuanceTicketPreviews(zupassServerUrl, email, orderCode, pipelineId) {
    return httpGetSimple(urlJoin(zupassServerUrl, `/generic-issuance/api/ticket-previews`, encodeURIComponent(email), encodeURIComponent(orderCode)) + (pipelineId ? `/${pipelineId}` : ""), async (resText) => ({
        value: JSON.parse(resText),
        success: true
    }), {});
}
